.chart-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 0.5rem;
    max-width: 500px;
    width: 100%;
}

.custom-legend {
    text-align: center;
    font-weight: bold;
    font-size: 12px;
    margin: 1px;
}

.chart-container canvas {
    margin: 1px;
}
